<template>
  <div>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>
    <div class="container">
      <div class="top">
        <div class="manual-text">Manual Sync</div>
        <p class="manual-desc">
          View, Download, or Sync Inventory to the Marketplace.
        </p>
      </div>
      <div class="group-name-container">
        <nitrozen-tab
          v-if="tabss.length"
          class="group-tab"
          :activeIndex="activeGroupIndex"
          @tab-change="changeGroupIndex"
          :tabItem="tabss"
          label="name"
        ></nitrozen-tab>
        <div class="tab-content" v-show="activeGroupIndex == 0">
          <div class="inventory-tab">
            <p class="inventory-helper-report regular-xs cl-DustyGray2">
              Filter inventory sent logs using below date filter, also if
              inventory triggered manually, it will take some time to reflect,
              Please use refresh icon, if needed.
            </p>
            <div class="date">
              <adm-date-picker
                class="date-picker"
                :date_format="'YYYY-MM-DD hh:mm:ss a'"
                :picker_type="'datetime'"
                v-model.trim="fromDate"
                :not_before="notBefore"
                :not_after="toDate"
                :label="'From*'"
              />
              <adm-date-picker
                class="date-picker"
                :picker_type="'datetime'"
                :date_format="'YYYY-MM-DD hh:mm:ss a'"
                v-model.trim="toDate"
                :not_before="fromDate"
                :not_after="currentDate"
                :label="'To*'"
              />
              <span @click="fetchLogs" class="refresh-icon">
                <adm-inline-svg class="svg-icon" src="refresh"></adm-inline-svg>
              </span>
            </div>
            <adm-no-content
              class="product-no-content"
              v-if="!(logs && logs.length) && !inProgress"
              helperText="No logs present"
            >
            </adm-no-content>
            <div class="headers" v-if="logs && logs.length">
              <div class="empty-space"></div>
              <div
                v-for="(header, index) in headers"
                :key="index"
                :class="[
                  'header',
                  'cl-Mako',
                  { 'sync-col-width': header.title === 'Sync' },
                ]"
                :style="header.style"
              >
                {{ header.title }}
              </div>
            </div>

            <div v-for="(log, index) in logs" :key="index">
              <div class="row">
                <div class="empty-space"></div>
                <div
                  v-for="(header, index) in headers"
                  :key="index"
                  :class="[
                    'row-value',
                    'cl-Mako',
                    {
                      'sync-col-width': header.title === 'Sync',
                    },
                  ]"
                  :style="header.style"
                >
                  <template v-if="header.type === 'text'">
                    <div class="text-overflow">
                      {{ renderLogsData(log, header)
                      }}

                      <!-- For Full Inventory events tooltip -->

                      <span
                        v-if="
                          header.key == 'event' &&
                          log.event_type == 'FULL_INVENTORY_UPDATES'
                        "
                      >
                        <nitrozen-tooltip class="header-tooltip" position="top">
                          Article quantity updates are sent to {{mkpName}}
                          marketplace for all the articles whose quantity was
                          updated in the selected time interval.
                        </nitrozen-tooltip>
                      </span>
                      <!-- End full Inv tooltip -->
                      <!-- Realtime Quantity update -->
                      <span
                        v-if="
                          header.key == 'event' &&
                          log.event_type == 'REALTIME_INVENTORY_UPDATE'
                        "
                      >
                        <nitrozen-tooltip class="header-tooltip" position="top">
                          Article quantity updates are sent to {{mkpName}}
                          marketplace in realtime for all the articles whose
                          quantity were updated. These realtime updates are
                          grouped together by date.
                        </nitrozen-tooltip>
                      </span>
                      <!--  -->
                      <!-- Realtime Inventory delete update -->
                      <span
                        v-if="
                          header.key == 'event' &&
                          log.event_type == 'REALTIME_INVENTORY_DELETE_UPDATE'
                        "
                      >
                        <nitrozen-tooltip class="header-tooltip" position="top">
                          When one or more active product is deleted from Fynd
                          Platform.
                        </nitrozen-tooltip>
                      </span>
                      <!--  -->
                      <!-- Inventory reset updates-->
                      <span
                        v-if="
                          header.key == 'event' &&
                          log.event_type == 'INVENTORY_RESET_UPDATES'
                        "
                      >
                        <nitrozen-tooltip class="header-tooltip" position="top">
                          When one or more location mapping is disabled from
                          {{mkpName}} extension
                        </nitrozen-tooltip>
                      </span>
                      <span
                        v-if="
                          header.key == 'event' &&
                          log.event_type == 'RETRY_INVENTORY_UPDATE'
                        "
                      >
                        <nitrozen-tooltip class="header-tooltip" position="top">
                          When article inventory update has failed and trying to
                          send update again
                        </nitrozen-tooltip>
                      </span>
                      <!--  -->
                      <span
                        v-if="
                          header.key === 'count' &&
                          getValue(log, 'status') === 'in_progress' &&
                          !isRealtimeOrSKULog(log.event_type)
                        "
                        >/{{ getValue(log, "totalItems") }}</span
                      >
                    </div>
                  </template>
                  <template
                    v-if="
                      header.type === 'badge' &&
                      !isRealtimeOrSKULog(log.event_type)
                    "
                  >
                    <nitrozen-badge
                      v-if="getValue(log, header.key) === 'partial_success'"
                      state="info"
                    >
                    PARTIAL SUCCESS
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-else-if="getValue(log, header.key) === 'success'"
                      state="success"
                    >
                      {{ getValue(log, header.key) }}
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-else-if="getValue(log, header.key) === 'failed'"
                      state="error"
                    >
                      {{ getValue(log, header.key) }}
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-else-if="getValue(log, header.key) === 'cancelled'"
                      state="info"
                    >
                      {{ getValue(log, header.key) }}
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-else-if="
                        getValue(log, header.key) === 'in_progress' &&
                        !checkStale(log)
                      "
                      state="info"
                    >
                      IN PROGRESS
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-if="
                        getValue(log, header.key) === 'in_progress' &&
                        checkStale(log)
                      "
                      state="info"
                    >
                      stale
                    </nitrozen-badge>
                    <nitrozen-badge
                      v-if="getValue(log, header.key) === 'pending'"
                      state="info"
                    >
                      pending
                    </nitrozen-badge>
                  </template>
                  <template v-if="header.type === 'button'">
                    <nitrozen-button
                      v-flatBtn
                      class="act-btn"
                      :key="getValue(log, header.key)"
                      theme="secondary"
                      :disabled="getValue(log, 'count') <= 0"
                      v-if="
                        getValue(log, header.key) === 'export' ||
                        getValue(log, header.key) === 'queued'
                      "
                      :showProgress="getValue(log, header.key) === 'queued'"
                      @click="
                        getValue(log, header.key) === 'export'
                          ? onExport(log)
                          : () => {}
                      "
                    >
                      Export CSV
                    </nitrozen-button>
                    <nitrozen-button
                      v-strokeBtn
                      class="act-btn"
                      theme="secondary"
                      v-else-if="getValue(log, header.key) === 'download'"
                      @click="downloadCsv(log.downloadUrl, log.event_type)"
                    >
                      Download CSV
                    </nitrozen-button>
                    <div v-if="log.message">
                      {{ log.message }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <nitrozen-pagination
            v-if="pagination.total > 0"
            name="logs"
            v-model.trim="pagination"
            :disable="disablePagination"
            @change="fetchLogs"
            :pageSizeOptions="[10, 20, 50]"
          >
          </nitrozen-pagination>
        </div>

        <!-- sync changes -->
        <div class="tab-content" v-show="activeGroupIndex == 1">
          <div class="trigger-tab">
            <div class="trigger-heading">Sync Inventory Manually</div>
            <p class="inventory-helper regular-xs cl-DustyGray2">
              These credentials will help us connect to the marketplace to sync
              all your fynd platform products and inventory to {{mkpName}}
              from time to time.
            </p>
            <div class="section">
              <div class="heading-section">
                <div
                  v-for="(step, index) of steps"
                  :key="'step' + index"
                  class="heading"
                  @click="activate(index)"
                  :class="currentStep == index ? 'is-active' : ''"
                >
                  <div class="name" :class="currentStep == index ? 'bold' : ''">
                    {{ step }}
                  </div>
                </div>
              </div>
              <div class="input-section">
                <div class="trigger-values">
                  <div
                    class="last-job-block"
                    v-if="isLastJobBlockOpen && !isEmpty(lastJobData)"
                    :style="setlastJobStatusColor"
                  >
                    <div
                      class="processingInJob"
                      v-if="lastJobData.status.toUpperCase() === 'IN_PROGRESS'"
                    >
                      <span class="running-loader"></span>
                    </div>
                    <div v-if="lastJobData.status.toUpperCase() === 'FAILED'">
                      <inline-svg
                        :src="'strip-warning'"
                        class="feature-icon preview-error-icon"
                      ></inline-svg>
                    </div>
                    <div
                      v-if="lastJobData.status.toUpperCase() === 'CANCELLED'"
                    >
                      <inline-svg :src="'strip-warning'"></inline-svg>
                    </div>
                    <div v-if="lastJobData.status.toUpperCase() === 'SUCCESS'">
                      <inline-svg
                        :src="'success-tick'"
                        class="feature-icon"
                      ></inline-svg>
                    </div>
                    <div
                      v-if="
                        lastJobData.status.toUpperCase() === 'PARTIAL_SUCCESS'
                      "
                    >
                      <inline-svg
                        :src="'tick'"
                        class="feature-icon"
                      ></inline-svg>
                    </div>
                    <span
                      >Inventory Sync last started at {{ formatLastJobDate }}.
                      Status: <span>{{ lastJobData.status.toUpperCase() }}</span
                      >. Go to
                      <div
                        class="redirect-report"
                        @click="gotoInventory(lastJobData.start_time, $event)"
                      >
                        <a oncontextmenu="return false;">Inventory Reports</a>
                      </div>
                      for more details.</span
                    >
                  </div>
                  <!-- Right hand side section for taking input of sync jobs -->
                  <div class="section-background">
                    <div v-if="steps[currentStep] == 'FP-Sync'">
                      <h4>Sync Now</h4>
                      <h6>Select resources to be synced from Fynd Platform.</h6>
                      <div
                        v-for="(sync, index) of fpSyncList"
                        :key="index"
                        class="fp-sync"
                      >
                        <div class="title-desc-block">
                          <div class="title">
                            {{ sync.title }}
                          </div>
                          <div class="description">
                            {{ sync.description }}
                          </div>
                          <div
                            class="resource-sync-last-job"
                            v-if="sync.lastRunAt"
                          >
                            <span>{{ sync.title }} sync last started at </span>
                            <span class="resource-sync-last-job-date">{{
                              formatLastResourceSyncDate(sync.lastRunAt)
                            }}</span>
                          </div>
                        </div>
                        <nitrozen-badge
                          state="info"
                          v-if="sync.lastJobStatus == 'progress'"
                          >IN PROGRESS
                        </nitrozen-badge>
                        <nitrozen-badge
                          state="success"
                          v-if="sync.lastJobStatus == 'completed'"
                          >COMPLETED
                        </nitrozen-badge>
                        <nitrozen-badge
                          state="error"
                          v-if="sync.lastJobStatus == 'failed'"
                          >FAILED
                        </nitrozen-badge>
                        <nitrozen-button
                          v-flatBtn
                          class="act-btn btn-1"
                          theme="secondary"
                          @click="resourceSyncFromFP(index)"
                          >Sync
                        </nitrozen-button>
                      </div>
                    </div>
                    <div v-else>
                      <h4>Sync Now</h4>
                      <div class="trigger-date-picker">
                        <nitrozen-tooltip class="date-tooltip" position="top">
                          Optional field. Leave it empty to sync all the mapped
                          Products.
                        </nitrozen-tooltip>
                        <adm-date-picker
                          class="identifier-suffix"
                          :date_format="'YYYY-MM-DD hh:mm:ss a'"
                          :picker_type="'datetime'"
                          v-model.trim="defaultSyncDate"
                          :not_before="notBefore"
                          :not_after="toDate"
                          :label="'Updates From'"
                        />
                      </div>
                      <div class="brand-loc-dropdown">
                        <div class="brand-loc-dropdown-1">
                          <nitrozen-dropdown
                            class="identifier-suffix"
                            label="Brands"
                            :multiple="true"
                            :searchable="true"
                            :items="brandValuesList"
                            :enable_select_all="
                              all_filter_eligible.has(`brands`)
                            "
                            v-model.trim="brandToBeSynced"
                            @change="setBrandSync"
                            @searchInputChange="inputChangeEvent($event)"
                          >
                          </nitrozen-dropdown>
                          <nitrozen-dropdown
                            class="identifier-suffix-1"
                            label="Selling Locations"
                            :items="enabledStore"
                            :multiple="true"
                            :searchable="true"
                            @change="setStoreSync"
                            :enable_select_all="
                              all_filter_eligible.has(`locations`)
                            "
                            v-model.trim="storeToBeSynced"
                          >
                          </nitrozen-dropdown>
                        </div>
                        <div class="sync-button">
                          <nitrozen-button
                            v-flatBtn
                            class="act-btn btn-1"
                            theme="secondary"
                            :disabled="!quantityStatus"
                            @click="inventorySync(syncType)"
                            >Sync
                          </nitrozen-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- sync changes end -->
      <div class="tab-content" v-show="activeGroupIndex == 2">
        <div>
          <div class="top-container">
            <div class="search-box-plus-log">
              <nitrozen-input
                type="search"
                class="search-identifier"
                placeholder="Search by"
                :showSearchIcon="true"
                v-model.trim="searchText"
                @keyup.enter="debounceSearch"
                :showSuffix="true"
                :custom="true"
              >
                <nitrozen-dropdown
                  class="identifier-suffix-2"
                  :items="productIdentifiers"
                  v-model.trim="productIdentifier"
                />
              </nitrozen-input>
              <nitrozen-button
                v-strokeBtn
                class="logs-btn"
                theme="secondary"
                @click="
                  setPagination();
                  fetchLogs();
                "
              >
                Get Logs
              </nitrozen-button>
            </div>
            <div class="date-1">
              <adm-date-picker
                class="trace-date-picker"
                :date_format="'YYYY-MM-DD hh:mm:ss a'"
                :picker_type="'datetime'"
                v-model.trim="fromDate2"
                :not_before="notBefore1"
                :not_after="toDate2"
                :label="'From*'"
              />
              <adm-date-picker
                class="trace-date-picker"
                :picker_type="'datetime'"
                :date_format="'YYYY-MM-DD hh:mm:ss a'"
                v-model.trim="toDate2"
                :not_after="currentDate"
                :not_before="fromDate2"
                :label="'To*'"
              />
              <nitrozen-button
                v-flatBtn
                class="export-btn"
                theme="secondary"
                :disabled="!logs.length"
                v-if="
                  getValue(currentSearchPayload, 'action') === 'export' ||
                  getValue(currentSearchPayload, 'action') === 'queued' ||
                  getValue(currentSearchPayload, 'action') === undefined
                "
                @click="onExport(currentSearchPayload)"
                :showProgress="
                  getValue(currentSearchPayload, 'action') === 'queued'
                "
              >
                Export
              </nitrozen-button>
              <nitrozen-button
                v-strokeBtn
                class="export-btn"
                theme="secondary"
                v-else-if="
                  getValue(currentSearchPayload, 'action') === 'download'
                "
                @click="
                  downloadCsv(
                    currentSearchPayload.downloadUrl,
                    currentSearchPayload.event_type
                  )
                "
              >
                Download CSV
              </nitrozen-button>
            </div>
          </div>
          <div class="dropdowns">
            <nitrozen-dropdown
              class="identifier-suffix-dropdown-1"
              :items="storeCodes"
              v-model.trim="selectedStoreFilter"
              @input="fetchLogs()"
              :searchable="true"
              @searchInputChange="searchStoreCodes"
              placeholder="Select code"
              label="Fynd store code"
            />

            <nitrozen-dropdown
              class="identifier-suffix-dropdown"
              :items="marketPlaceIds"
              v-model.trim="selectedMarketPlaceFilter"
              @input="fetchLogs()"
              :searchable="true"
              @searchInputChange="searchMarketPlaceCodes"
              placeholder="Select ID"
              label="Marketplace location ID"
            />
            <nitrozen-dropdown
              class="identifier-suffix-dropdown"
              :items="statusIdentifier"
              v-model.trim="selectStatus"
              @change="fetchLogs()"
              placeholder="Select Status"
              label="Status"
            />
            <nitrozen-dropdown
              class="identifier-suffix-dropdown"
              :items="eventIdentifier"
              v-model.trim="selectEvent"
              @change="fetchLogs()"
              placeholder="Select Events"
              label="Events"
            />
          </div>
          <adm-no-content
            class="product-no-content"
            v-if="!inProgress && searchText && noLogs && fromDate1 && toDate1"
            helperText="No logs present"
          >
          </adm-no-content>
          <div class="trace-headers" v-if="logs && logs.length">
            <div class="empty-space"></div>
            <div
              v-for="(header, index) in traceHeaders"
              :key="index"
              :class="['header', 'cl-Mako']"
              :style="header.style"
            >
              {{ header.title }}
            </div>
          </div>
          <div v-for="(log, index) in logs" :key="index">
            <div class="row">
              <div class="empty-space"></div>
              <div
                v-for="(header, index) in traceHeaders"
                :key="index"
                :class="['row-value', 'cl-Mako']"
                :style="header.style"
              >
                <template v-if="header.type === 'text'">
                  <div class="text-overflow">
                    {{ getValue(log, header.key) }}
                  </div>
                </template>
                <template v-if="header.type === 'badge'">
                  <nitrozen-badge
                    v-if="getValue(log, header.key) === 'partial_success'"
                    state="info"
                  >
                    {{ getValue(log, header.key) }}
                  </nitrozen-badge>
                  <nitrozen-badge
                    v-else-if="getValue(log, header.key) === 'success'"
                    state="success"
                  >
                    {{ getValue(log, header.key) }}
                  </nitrozen-badge>
                  <nitrozen-badge
                    v-else-if="getValue(log, header.key) === 'failed'"
                    state="error"
                  >
                    {{ getValue(log, header.key) }}
                  </nitrozen-badge>
                  <nitrozen-badge
                    v-else-if="getValue(log, header.key) === 'cancelled'"
                    state="info"
                  >
                    {{ getValue(log, header.key) }}
                  </nitrozen-badge>
                </template>
              </div>
            </div>
          </div>
        </div>
        <nitrozen-pagination
          v-if="pagination.total > 0"
          name="logs"
          v-model.trim="pagination"
          :disable="disablePagination"
          @change="fetchLogs"
          :pageSizeOptions="[10, 20, 50]"
        >
        </nitrozen-pagination>
      </div>
    </div>
    <loader class="loading" v-if="inProgress"></loader>
  </div>
</template>

<script>
import EXT from "../extension_bridge";
import {
  NitrozenButton,
  flatBtn,
  strokeBtn,
  NitrozenInput,
  NitrozenDropdown,
  NitrozenTooltip,
  NitrozenBadge,
  NitrozenPagination,
  NitrozenTab,
} from "@gofynd/nitrozen-vue";
import AdminMarketplacesService from "@/services/main.service";
import datePicker from "@/components/common/date-picker.vue";
import loader from "@/components/common/adm-loader.vue";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import admInlineSvg from "@/components/common/inline-svg.vue";
import { setCompany } from "@/helper/utils.js";
import { debounce } from "@/helper/utils";
import admNoContent from "@/components/common/adm-no-content.vue";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import InlineSvg from "@/components/common/inline-svg";
import { MAX_INTERVAL,MARKETPLACE_NAME } from "@/helper/constant.js";

const HEADERS = [
  {
    title: "Date",
    type: "text",
    key: "start_time",
    style: "flex:0.25",
    tooltip: "For this event we will keep time as starting time of the day",
  },
  {
    title: "Event",
    type: "text",
    key: "event",
    style: "flex:0.25",
    tooltip:
      `Article quantity updates are sent to ${MARKETPLACE_NAME} marketplace for all the articles whose SKU mapping is updated or active flag was updated.`,
  },
  {
    title: "Count",
    type: "text",
    key: "count",
    style: "flex:0.15",
  },
  {
    title: "Status",
    type: "badge",
    key: "status",
    style: "flex:0.15",
  },
  {
    title: "Action",
    type: "button",
    key: "action",
    style: "flex:0.3",
  },
];
const TRACE_HEADERS = [
  {
    title: "Event",
    type: "text",
    key: "event",
    // style: "flex:0.25",
  },
  {
    title: "Date",
    type: "text",
    key: "start_time",
    // style: "flex:0.25",
  },
  {
    title: "Seller Identifier",
    type: "text",
    key: "seller_identifier",
    // style: "flex:0.25",
  },
  {
    title: `${MARKETPLACE_NAME} SKU`,
    type: "text",
    key: "channel_identifier.sku",
    // style: "flex:0.25",
  },
  {
    title: "Store Code",
    type: "text",
    key: "store_code",
    // style: "flex:0.25",
  },
  {
    title: "Marketplace location id",
    type: "text",
    key: "marketplace_store_id",
    // style: "flex:0.25",
  },
  {
    title: "Quantity",
    type: "text",
    key: "sellable_quantity",
    // style: "flex:0.15",
  },

  {
    title: "Status",
    type: "badge",
    key: "status",
    // style: "flex:0.15",
  },
];

const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};
const TRACE_EVENTS = {
  FULL_INVENTORY_UPDATES: "FULL INVENTORY UPDATES",
  REALTIME_INVENTORY_DELETE_UPDATE: "REALTIME INVENTORY DELETE UPDATE",
  REALTIME_INVENTORY_UPDATE: "REALTIME INVENTORY UPDATE",
  INVENTORY_RESET_UPDATES: "INVENTORY RESET UPDATES",
  RETRY_INVENTORY_UPDATE: "RETRY INVENTORY UPDATES",
};
const EVENT_TYPE = {
  FULL_INVENTORY_UPDATES: "FULL INVENTORY UPDATES",
  REALTIME_INVENTORY_DELETE_UPDATE: "REALTIME INVENTORY DELETE UPDATE",
  REALTIME_INVENTORY_UPDATE: "REALTIME INVENTORY UPDATE",
  INVENTORY_RESET_UPDATES: "INVENTORY RESET UPDATES",
  RETRY_INVENTORY_UPDATE: "RETRY INVENTORY UPDATES",
  INVENTORY_ENABLE: "FULL INVENTORY UPDATES",
};
const EVENT_TYPES = ["FULL_INVENTORY_UPDATES", "INVENTORY_ENABLE"];

const NAVS = [
  {
    title: "Home",
    link: "/",
  },
  { title: "Marketplace Status", link: "/manual-trigger" },
];
const MAX_POLLING_COUNTER = 80;
const LAST_SYNC_STATUS = {
  SUCCESS: "#E9F5EE",
  PENDING: "##E7EEFF",
  FAILED: "#FFE7E7",
  STALE: "#FFE7E7",
  CANCELLED: "#FFE7E7",
  "PARTIAL SUCCESS": "#E7EEFF",
  "IN PROGRESS": "#FFFFFF",
};
export default {
  name: "manual-trigge",
  components: {
    "nitrozen-tab": NitrozenTab,
    "custom-breadcrumb": breadCrumb,
    "adm-date-picker": datePicker,
    "adm-inline-svg": admInlineSvg,
    "nitrozen-badge": NitrozenBadge,
    "nitrozen-button": NitrozenButton,
    "nitrozen-pagination": NitrozenPagination,
    "nitrozen-tooltip": NitrozenTooltip,
    "nitrozen-dropdown": NitrozenDropdown,
    "adm-no-content": admNoContent,
    "nitrozen-input": NitrozenInput,
    "inline-svg": InlineSvg,
    loader,
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  data() {
    return {
      activeGroupIndex: 0,
      navs: NAVS,
      tabss: [],
      marketplace: "",
      mkpName:MARKETPLACE_NAME,
      logs: "",
      allStores: [],
      companyId: "",
      helperText: "",
      headers: HEADERS,
      inProgress: false,
      selectedStoreFilter: "",
      selectedMarketPlaceFilter: "",
      searchText: "",
      disablePagination: "",
      brandValuesList: [],
      selectedBrandFilter: "",
      logsData: {},
      storeCodes: [],
      pagination: cloneDeep(PAGINATION),
      isActive: false,
      selectStatus: "",
      quantityStatus: true,
      storeToBeSynced: [],
      syncDisabledLocation:false,
      syncDisabledSku:false,
      storeCodeToBeSynced: [],
      brandToBeSynced: [],
      marketPlaceIds: [],
      selectEvent: "",
      noLogs: false,
      marketPlaceCodeToBeSynced: [],
      enabledStore: [],
      products: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "active",
          value: "true",
        },
        {
          text: "inactive",
          value: "false",
        },
      ],
      productIdentifier: "seller_identifier",
      selectedBrands: "all",
      selectedLocations: "all",
      selectedProducts: "all",
      productIdentifiers: [
        {
          text: "Seller Identifier",
          value: "seller_identifier",
        },
        {
          text: `${MARKETPLACE_NAME} SKU`,
          value: "channel_identifier",
        },
      ],
      statusIdentifier: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Success",
          value: "success",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
      ],
      eventIdentifier: [
        {
          text: "All",
          value: ["all"],
        },
        {
          text: "FULL INVENTORY UPDATES",
          value:["FULL_INVENTORY_UPDATES", "INVENTORY_ENABLE"],
        },
        {
          text: "REALTIME INVENTORY DELETE UPDATE",
          value: ["REALTIME_INVENTORY_DELETE_UPDATE"],
        },
        {
          text: "REALTIME INVENTORY UPDATE",
          value: ["REALTIME_INVENTORY_UPDATE"],
        },
        {
          text: "INVENTORY RESET UPDATES",
          value: ["INVENTORY_RESET_UPDATES"],
        },
        {
          text: "RETRY INVENTORY UPDATE",
          value: ["RETRY_INVENTORY_UPDATE"],
        },
      ],
      codeStores: [],
      searchMarketPlaceId: [],
      traceHeaders: TRACE_HEADERS,
      fromDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      defaultSyncDate: new Date(
        new Date().setHours(-24, 0, 0, 0)
      ).toISOString(),
      fromDate1: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      fromDate2: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      toDate: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
      // add current Date
      currentDate: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
      notBefore: new Date(
        moment().subtract(6, "months").startOf("month")
      ).toDateString(),
      toDate1: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
      toDate2: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
      notBefore1: new Date(
        moment().subtract(29, "days").startOf("month")
      ).toDateString(),
      currentSearchPayload: {},
      from: "",
      isFyndPlatforms:
        this.$route.params.MARKETPLACE_NAME === "fynd" ||
        this.$route.params.MARKETPLACE_NAME === "uniket_wholesale",
      steps: ["Quantity", "FP-Sync"],
      resourceSyncStep: "FP-Sync",
      currentStep: 0,
      syncType: "quantity",
      isLastJobBlockOpen: true,
      lastJobData: {},
      all_filter_eligible: new Set(["brands", "locations"]),
      pendingStatusInterval: 0,
      pendingResourceSyncInterval: 0,
      fpSyncList: [
        {
          title: "Locations",
          description: "Sync locations from Fynd Platfrom.",
          isSelected: false,
          value: "locations",
          lastJobStatus: "",
          lastRunAt: "",
        },
        {
          title: "Products",
          description: "Sync products from Fynd Platfrom.",
          isSelected: false,
          value: "products",
          lastJobStatus: "",
          lastRunAt: "",
        },
      ],
      isLocationSelected: false,
    };
  },
  watch: {
    fromDate() {
      this.handleDateChange();
    },
    toDate() {
      this.handleDateChange();
    },
    activeGroupIndex() {
      if (this.currentSearchPayload.pollingId)
        clearInterval(this.currentSearchPayload.pollingId);
      this.currentSearchPayload = {};
      if (this.tabss[this.activeGroupIndex].name === "Inventory Reports") {
        this.fetchLogs();
      } else if (this.tabss[this.activeGroupIndex].name === "Sync") {
        this.logs = {};
        this.searchText = "";
        this.pagination = cloneDeep(PAGINATION);
        this.brandToBeSynced = this.brandValuesList.map(
          (brandVal) => brandVal.value
        );
        this.storeToBeSynced = this.enabledStore.map(
          (storeVal) => storeVal.value
        );
      } else if (this.tabss[this.activeGroupIndex].name === "Trace Product") {
        clearInterval(this.pendingStatusInterval);
        this.logs = {};
        //clear filter
        // this.searchText = "";
        // this.selectedStoreFilter = "";
        // this.selectedMarketPlaceFilter = "";
        // this.selectStatus = "";
        // this.selectEvent = "";

        this.pagination = cloneDeep(PAGINATION);
      }
    },
  },
  mounted() {
    this.companyId = this.$route.params.company_id;

    if (!this.isFyndPlatforms) {
      this.tabss.push(
        {
          name: "Inventory Reports",
        },
        {
          name: "Sync",
        },
        {
          name: "Trace Product",
        }
      );
    }
    console.log("check", !!this.$route.query.from);
    this.fromDate = this.$route.query.from
      ? this.$route.query.from
      : this.fromDate;
    let query = {
      page: 1,
      limit: 10000,
    };
    AdminMarketplacesService.fetchStoreMapping(query).then(({ data }) => {
      this.setStoreData(data);
      this.inProgress = false;
    });
    AdminMarketplacesService.getInventorySyncConfig().then(({ data }) => {
      this.quantityStatus = data.quantity;
    });

    this.changeGroupIndex({ index: 0 });
    this.initializeComponent();
    console.log(this.logs);
    console.log("the quantityStatus is", this.quantityStatus);
    this.lastJobInfo({
      page_no: 1,
      page_size: 1,
      event_type: "FULL_INVENTORY_UPDATES",
    });
  },
  computed: {
    formatLastJobDate() {
      const gmtDateTime = moment.utc(
        this.lastJobData.start_time,
        "YYYY-MM-DD HH:mm:ss"
      );
      return gmtDateTime.local().format("MMM Do YYYY, hh:mm:ss A");
    },
    setlastJobStatusColor() {
      return {
        background: LAST_SYNC_STATUS[this.lastJobData.status.toUpperCase()],
      };
    },
  },
  methods: {
    handleDateChange() {
      this.pagination = cloneDeep(PAGINATION);
      if (
        this.tabss[this.activeGroupIndex].name === "Trace Product" ||
        this.tabss[this.activeGroupIndex].name === "Sync"
      ) {
        return;
      }
      this.fetchLogs();
    },
    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const oneDay = 1000 * 60 * 60 * 24; // One day in milliseconds

      const diffInTime = date2.getTime() - date1.getTime(); // Calculating the time difference between two dates

      const diffInDays = Math.ceil(diffInTime / oneDay); // Calculating the no. of days between two dates
      console.log("days ", diffInDays);
      return diffInDays;
    },
    getLogFromItems(items) {
      return items.map((elem) => {
        const gmtDateTime = moment.utc(
          elem.created_at,
          "YYYY-MM-DD HH:mm:ss"
        );

        elem.event = TRACE_EVENTS[elem.event_type] || elem.event_type;
        elem.start_time = gmtDateTime
          .local()
          .format("MMM Do YYYY, hh:mm:ss A");
        elem.start_date = gmtDateTime.local().format("DD-MM-YYYY");
        elem.store_code = elem?.store_id;
        return elem;
      });
    },
    getFetchedLogs() {
      let fetchedLogs = this.logsData;
      this.inProgress = false;
      let items = fetchedLogs.slice(
        this.pagination.limit * (this.pagination.current - 1),
        this.pagination.limit * this.pagination.current
      );
      this.logs = this.getLogFromItems(items);
      if (!this.logs.length) this.noLogs = true;
      this.currentSearchPayload.action = "export";
      this.$forceUpdate();
      this.pagination.total = fetchedLogs.length;
    },
    renderLogsData(log, header) {
      if (header.key === "count" && this.isRealtimeOrSKULog(log.event_type))
        return this.getValue(log, "totalItems");
      else return this.getValue(log, header.key);
    },
    isRealtimeOrSKULog(event_type) {
      return (
        event_type.includes("REALTIME") ||
        event_type.includes("RETRY")
      );
    },
    checkStale(log) {
      let date = new Date();
      let timeDifference =
        Math.abs(date - new Date(log.end_time)) / (60 * 1000);
      return timeDifference > 360;
    },
    inputChangeEvent(val) {
      this.brandValuesList = this.brands;
      let filters;
      if (val && val.text) {
        filters = this.brands.filter((opt) => {
          return opt.text.toLowerCase().indexOf(val.text.toLowerCase()) > -1;
        });
      } else {
        filters = this.brandValuesList;
      }
      this.brandValuesList = filters || [];
      this.$forceUpdate();
    },
    gotoInventory(param, event) {
      if (event.ctrlKey || event.metaKey) {
        window.location.reload();
      } else {
        let link = document.createElement("a");
        link.href = "";
        this.$router.push({
          path: `/company/${this.companyId}/manual-trigger`,
          query: { from: param },
        });
        link.click();
      }
    },
    lastJobInfo(params) {
      AdminMarketplacesService.getLastJobInfo(params).then(({ data }) => {
        const { items } = data;
        this.lastJobData =
          !!items.length &&
          items[0].event_type === params.event_type &&
          items[0];
      });
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    setPagination() {
      this.pagination = cloneDeep(PAGINATION);
    },
    setStoreData(data) {
      this.allStores = data.items;
      this.populateStoreStats();
    },
    initializeComponent() {
      this.inProgress = true;
      AdminMarketplacesService.getCompanyStatus(
        this.companyId,
        this.marketplace
      )
        .then(({ data }) => {
          this.isActive = data.is_active;
          if (this.isActive) {
            this.fetchLogs();
          } else {
            this.inProgress = false;
          }
          return Promise.all([
            AdminMarketplacesService.fetchBrandsByCompany(),
            // this.getMappedSkuList(),
          ]);
        })
        .then((res) => {
          console.log(res.data);
          this.pageLoading = false;
          if (!res) return;

          let { brands } = {
            brands: res[0].data.brands,
          };
          this.brands =
            brands &&
            brands.map((brand) => {
              brand.text = brand.name;
              return brand;
            });
          if (this.brands.length) {
            this.selectedBrand = this.brands[0].value;
            this.brandValuesList = [...this.brands];
          }
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
    syncInActiveArticle(e){
      this.syncDisabledSku = e.target.checked;
    },
    resetStore(e){
      this.syncDisabledLocation = e.target.checked;
      this.populateStoreStats(this.syncDisabledLocation);
      this.storeToBeSynced = this.enabledStore.map((store) => {
          return store.value;
        });
    },
    setStoreSync(e) {
      if (e == "all" || e.includes("all")) {
        this.storeToBeSynced = this.enabledStore.map((store) => {
          return store.value;
        });
        [, ...this.storeToBeSynced] = this.storeToBeSynced;
      } else {
        this.storeToBeSynced = e;
      }
    },
    setBrandSync(e) {
      if (e == "all" || e.includes("all")) {
        this.brandToBeSynced = this.brandValuesList.map((brand) => {
          return brand.value;
        });
      } else {
        this.brandToBeSynced = e;
      }
    },
    populateStoreStats(syncDisabledLocation=false) {
      const aCount = syncDisabledLocation ? this.allStores.filter((store)=>store.marketplace_store_id) : 
      this.allStores.filter((store) => store.marketplace_store_id && store.enabled);
      let aCount2 = this.allStores.filter(
        (store) => store.marketplace_store_id
      );
      let activeStores = aCount.map((store) => {
        return {
          text: `${store.name}`,
          value: store.store_id,
        };
      });
      let allStoreCodes = aCount2.map((store) => {
        return {
          text: `${store.store_code}`,
          value: store.store_code,
        };
      });
      this.codeStores = [...allStoreCodes];
      let allMarketPlaceCodes = aCount2.map((store) => {
        return {
          text: `${store.marketplace_store_id}`,
          value: store.marketplace_store_id,
        };
      });
      this.searchMarketPlaceId = [...allMarketPlaceCodes];
      // code for store_code
      if (allStoreCodes && allStoreCodes.length) {
        this.storeCodes = [...allStoreCodes];
      } else {
        this.storeCodes = [];
      }
      if (allMarketPlaceCodes && allMarketPlaceCodes.length) {
        this.marketPlaceIds = [...allMarketPlaceCodes];
      } else {
        this.marketPlaceIds = [];
      }
      if (activeStores && activeStores.length) {
        this.enabledStore = [...activeStores];
      } else {
        this.enabledStore = [];
      }
    },
    getValue(obj, key) {
      return get(obj, key);
    },
    changeGroupIndex(item) {
      this.activeGroupIndex = item.index;
    },
    debounceSearch: debounce(() => {
      this.setPagination();
      this.fetchLogs();
    }, 300),
    fetchLogs() {
      if (!this.fromDate || !this.fromDate2) {
        this.$snackbar.global.showError("From date is required");
        return;
      }
      if (!this.toDate || !this.toDate2) {
        this.$snackbar.global.showError("To date is required");
        return;
      }
      if (this.tabss[this.activeGroupIndex].name == "Sync") {
        this.inProgress = true;
      } else if (this.tabss[this.activeGroupIndex].name == "Trace Product") {
        clearInterval(this.pendingStatusInterval);
        if (!this.productIdentifier || !this.searchText) {
          this.$snackbar.global.showError("Please provide Identifier");
          return;
        }
        if (this.getNumberOfDays(this.fromDate2, this.toDate2) > MAX_INTERVAL) {
          this.$snackbar.global.showError(
            "*Interval should not be more than 7 days*"
          );
          return;
        }
        this.inProgress = true;
        this.noLogs = false;
        let query = {
          page_no: this.pagination.current,
          page_size: this.pagination.limit,
        };
        if (this.currentSearchPayload.jobId) {
          clearInterval(this.currentSearchPayload.jobId);
        }
        if (this.selectedStoreFilter) {
          query["store_code"] = this.selectedStoreFilter;
        }
        if (this.selectedMarketPlaceFilter) {
          query["marketplace_store_id"] = this.selectedMarketPlaceFilter;
        }
        if (this.selectStatus != "all") {
          query["status"] = this.selectStatus;
        }
        if (this.selectEvent != "all") {
          query["event_type"] = this.selectEvent;
        }
        console.log(query);
        this.currentSearchPayload = {
          ...query,
          start_date: this.fromDate2,
          end_date: this.toDate2,
          identifier: this.productIdentifier,
          q: this.searchText.trim(),
        };
        console.log(this.currentSearchPayload);
        AdminMarketplacesService.traceProductInventory(
          this.currentSearchPayload
        )
          .then(({ data }) => {
            this.inProgress = false;
            this.logsData = data;
            let items = data.slice(
              this.pagination.limit * (this.pagination.current - 1),
              this.pagination.limit * this.pagination.current
            );
            this.logs = this.getLogFromItems(items);
            if (!this.logs.length) this.noLogs = true;
            this.currentSearchPayload.action = "export";
            this.$forceUpdate();
            this.pagination.total = data.length;
            this.pagination.limit = data.page.size;
            this.pagination.current = data.page.current;
          })
          .catch(() => {
            this.helperText = "something went wrong";
            this.inProgress = false;
          });
      }
      // code of logs shown in Inventory Reports-
      else {
        this.inProgress = true;
        let query = {
          page_no: this.pagination.current,
          page_size: this.pagination.limit,
          start_date: this.fromDate,
          end_date: this.toDate,
        };
        console.log("Query", query);
        clearInterval(this.pendingStatusInterval);
        let pendingJobs = this.callLogsApi(query);
        this.pendingStatusInterval = setInterval(() => {
          if (pendingJobs.length === 0 || this.activeGroupIndex !== 0) {
            clearInterval(this.pendingStatusInterval);
          }
          pendingJobs = this.callLogsApi(query);
        }, 10000);
      }
    },
    callLogsApi(query) {
      const pendingJobsArr = [];
      AdminMarketplacesService.fetchMarketPlaceLogs(query)
        .then(({ data }) => {
          this.inProgress = false;
          this.logs = data.items.map((elem) => {
            elem.end_time = elem.end_time_iso;
            const gmtDateTime = moment.utc(
              elem.start_time,
              "YYYY-MM-DD HH:mm:ss"
            );
            elem.event = EVENT_TYPE[elem.event_type] || elem.event_type;
            elem.start_time = gmtDateTime
              .local()
              .format("MMM Do YYYY, hh:mm:ss A");
            elem.start_date = gmtDateTime.local().format("DD-MM-YYYY");
            elem.action = "export";
            return elem;
          });
          this.pagination.total = data.page.item_total;
          this.pagination.limit = data.page.size;
          this.pagination.current = data.page.current;
          pendingJobsArr.push(
            ...new Set(
              this.logs
                .filter((job) => job.status === "in_progress" && job.jobId)
                .map((pendingJob) => pendingJob.jobId)
            )
          );
        })
        .catch(() => {
          this.helperText = "something went wrong";
          this.inProgress = false;
          this.logs = null;
        });
      return pendingJobsArr;
    },
    onExport(row) {
      if (isEmpty(row)) {
        return;
      }
      let body = {};
      if (this.tabss[this.activeGroupIndex].name === "Sync") {
        this.inProgress = true;
      }
      if (this.tabss[this.activeGroupIndex].name === "Trace Product") {
        body.start_time =
          this.currentSearchPayload && this.currentSearchPayload.start_date;
        body.end_time =
          this.currentSearchPayload && this.currentSearchPayload.end_date;
        body.identifier =
          this.currentSearchPayload && this.currentSearchPayload.identifier;
        body.q = this.currentSearchPayload && this.currentSearchPayload.q;
        if (this.selectedStoreFilter) {
          body.store_code = this.selectedStoreFilter;
        }
        if (this.selectedMarketPlaceFilter) {
          body.marketplace_store_id = this.selectedMarketPlaceFilter;
        }
        if (this.selectStatus) {
          body.status = this.selectStatus;
        }
        if (this.selectEvent) {
          body.event_type = this.selectEvent;
        }
        console.log("data comes in body are", body);
      } else if (
        this.tabss[this.activeGroupIndex].name === "Inventory Reports"
      ) {
        body.trace_id = row.trace_id[0];
        body.start_date = row.start_date;
        body.export_type = "inv-sync";
      }
      AdminMarketplacesService.exportMarketPlaceLogs(body)
        .then(({ data }) => {
          console.log("queued data log", data);
          if (data.status === "queued") {
            row.action = "queued";
            this.$forceUpdate();
            row.jobId = data.job_id;
            row.message = "";
            this.disablePagination = true;
            this.pollJobStatus(row, this.interval, 0);
          } else if (data.status === "success") {
            row.downloadUrl = data.download_url;
            row.action = "download";
            this.disablePagination = this.checkAllExportsFinished();
          }
        })
        .catch((err) => {
          row.action = "export";
          row.message = "export failed. try again";
          this.disablePagination = this.checkAllExportsFinished();
          console.log("the error comming is", err);
        });
    },
    pollJobStatus(row, interval, count) {
      if (count === MAX_POLLING_COUNTER) {
        row.action = "export";
        row.message = "export failed. try again.";
        return;
      }
      if (count === 5) interval = 15000;
      clearInterval(this.pendingStatusInterval);
      row.pollingId = setTimeout(() => {
        AdminMarketplacesService.getExportLogsJobStatus(row.jobId)
          .then(({ data }) => {
            console.log("status check", data);
            if (data.status !== "success") {
              return this.pollJobStatus(row, interval, ++count);
            }
            row.downloadUrl = data.download_url;
            row.action = "download";
            console.log("after status check", row);
            this.$forceUpdate();
            this.disablePagination = this.checkAllExportsFinished();
          })
          .catch((err) => {
            row.action = "export";
            row.message = "export failed. try again";
            console.log(err);
          });
      }, interval);
    },
    downloadCsv(url, fileName) {
      this.inProgress = true;
      setTimeout(() => {
        if (!isEmpty(url)) {
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}_${this.companyId}`);
          document.body.appendChild(link);
          link.click();
          this.$snackbar.global.showSuccess("Downloaded log successfully");
          document.body.removeChild(link);
        }
        this.inProgress = false;
        this.helperMsg = "";
        this.errMsg = "";
      }, 100);
    },
    checkAllExportsFinished() {
      if (!this.logs || !this.logs.length) return false;

      return this.logs.some((elem) => {
        return elem.action == "queued";
      });
    },
    clearStoreFilter() {
      this.selectedStoreFilter = "";
    },
    searchStoreCodes(e = {}) {
      if (!e.text) {
        this.clearStoreFilter();
        this.fetchLogs();
      }
      this.storeCodes = [];
      this.codeStores.forEach((b) => {
        if (
          !e ||
          !e.text ||
          b.text.toLowerCase().indexOf(e.text.toLowerCase()) > -1
        ) {
          this.storeCodes.push({
            text: b.text,
            value: b.value,
          });
        }
      });
    },

    clearMarketPlaceIdFilter() {
      this.selectedMarketPlaceFilter = "";
    },
    searchMarketPlaceCodes(e = {}) {
      if (!e.text) {
        this.clearMarketPlaceIdFilter();
        this.fetchLogs();
      }
      this.marketPlaceIds = [];
      this.searchMarketPlaceId.forEach((b) => {
        if (
          !e ||
          !e.text ||
          b.text.toLowerCase().indexOf(e.text.toLowerCase()) > -1
        ) {
          this.marketPlaceIds.push({
            text: b.text,
            value: b.value,
          });
        }
      });
    },
    // Code to perform full sync.
    inventorySync(type) {
      let body = {};
      let storeSynced;
      let brandSynced;
      // getting all store listing
      if (this.storeToBeSynced.length === 0) {
        storeSynced = this.enabledStore.map((store) => {
          return store.value;
        });
        [...storeSynced] = storeSynced;
      } else {
        storeSynced = this.storeToBeSynced;
      }

      // getting all brand listing
      if (this.brandToBeSynced.length === 0) {
        brandSynced = this.brandValuesList.map((brand) => {
          return brand.value;
        });
        [...brandSynced] = brandSynced;
      } else {
        brandSynced = this.brandToBeSynced;
      }
      // payload passed
      body = {
        from: this.defaultSyncDate,
        brands: brandSynced,
        stores: storeSynced,
        update_type: type,
        store_action: "update",
        sync_disabled_sku:this.syncDisabledSku,
        sync_disabled_location:this.syncDisabledLocation
      };
      AdminMarketplacesService.triggerInvUpdates(body)
        .then(({ data }) => {
          this.$snackbar.global.showSuccess(
            `Inventory updates triggered successfully. It will take sometime to complete.`
          );
          console.log(data.task_id);
          clearInterval(this.pendingStatusInterval);
          setTimeout(() => {
            let link = document.createElement("a");
            link.href = "";
            link.click();
          }, 1500);
        })
        .catch((err) => {
          this.$snackbar.global.showError(`Failed to send inventory updates`);
          console.log("there is some error", err);
        });
    },
    resourceSyncFromFP(index) {
      const resource = this.fpSyncList[index].value;
      const body = {
        [resource]: true,
      };
      console.log("body", body);
      AdminMarketplacesService.triggerResourceSync(body)
        .then(({ data }) => {
          this.$snackbar.global.showSuccess(
            `${this.fpSyncList[index].title} sync triggered successfully. It will take sometime to complete.`
          );
          this.getResourceSyncJobsInfo();
          console.log(data.task_id);
        })
        .catch((err) => {
          this.$snackbar.global.showError(
            `Failed to run ${this.fpSyncList[index].title} sync.`
          );
          console.log("there is some error", err);
        });
    },
    //
    resetForm() {
      this.from = "";
      this.brandToBeSynced = this.brandValuesList.map(
        (brandVal) => brandVal.value
      );
      this.storeToBeSynced = this.enabledStore.map(
        (storeVal) => storeVal.value
      );
    },
    activate(index) {
      this.resetForm();
      this.currentStep = index;
      this.syncType = this.steps[index].toLowerCase();
      console.log("debug | sync Type", this.syncType);
      this.inProgress = true;
      if (this.resourceSyncStep.toLowerCase() == this.syncType) {
        this.getResourceSyncJobsInfo().catch((err) => {
          console.log(
            "error | Error in fetching resource sync job status",
            err
          );
        });
      } else {
        console.log("debug | calling last sync job too", true);
        AdminMarketplacesService.getLastJobInfo({
          page_no: 1,
          page_size: 1,
          event_type: EVENT_TYPES[index],
        }).then(({ data }) => {
          const { items } = data;
          this.lastJobData = this.lastJobData =
            !!items.length &&
            items[0].event_type === EVENT_TYPES[index] &&
            items[0];
          this.inProgress = false;
        });
      }
    },
    getResourceSyncJobsInfo(syncType = "all") {
      /***
       * get last job info for last resource sync job
       */
      this.lastJobData = {};

      let resourceSyncPromises = [];

      console.log("debug | calling resource sync jobs", true);

      let syncTypeRequests = [];

      for (let sync of this.fpSyncList) {
        if (syncType != "all" && syncType != sync.value) {
          continue;
        }
        const promise = AdminMarketplacesService.getResourceSyncLastJobInfo(
          {},
          sync.value
        );
        syncTypeRequests.push(sync.value);
        resourceSyncPromises.push(promise);
      }

      return Promise.allSettled(resourceSyncPromises).then((resp) => {
        console.log("debug | response of resource sync last job", resp);

        let pollResourceSyncStatus = {};

        syncTypeRequests.forEach((syncTypeReq, idx) => {
          if (!resp[idx].value.data) {
            return;
          }
          if (resp[idx].status == "rejected") {
            const err = resp[idx].reason;
            console.log(
              "Error | Resource Sync Last Job Status Fetch Failed",
              syncTypeReq,
              err
            );
            return;
          }

          const syncIdx = this.fpSyncList.findIndex(
            (fpSync) => fpSync.value == syncTypeReq
          );

          console.log(
            "syncIdx",
            syncIdx,
            "syncType",
            syncTypeReq,
            "this.fpSyncList",
            this.fpSyncList
          );
          if (resp[idx].value.data.error_jobs?.length) {
            this.fpSyncList[syncIdx].lastJobStatus = "failed";
          } else if (
            resp[idx].value.data.total_jobs ==
            resp[idx].value.data.finished_jobs?.length
          ) {
            this.fpSyncList[syncIdx].lastJobStatus = "completed";
          } else {
            pollResourceSyncStatus[syncTypeReq] = true;
            this.fpSyncList[syncIdx].lastJobStatus = "progress";
          }
          console.log("timestamp", resp[idx].value.data.lastRunAt);
          this.fpSyncList[syncIdx].lastRunAt = new Date(
            resp[idx].value.data.created_at
          );
          if (pollResourceSyncStatus[syncTypeReq]) {
            console.log("calling again", syncTypeReq);
            setTimeout(() => {
              this.getResourceSyncJobsInfo(syncTypeReq);
            }, 5000);
          }
        });
        console.log("debug | this.fpSyncList", this.fpSyncList);
        this.inProgress = false;
        this.$forceUpdate();
      });
    },
    formatLastResourceSyncDate(lastRunAt) {
      const gmtDateTime = moment.utc(lastRunAt, "YYYY-MM-DDTHH:mm:ssZ");
      return gmtDateTime.local().format("MMM Do YYYY, hh:mm:ss A");
    },
  },
  beforeRouteEnter: (to, from, next) => {
    console.log("to-logs", to);
    console.log("from-logs", from);
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
  destroyed() {
    EXT.destroy();
    clearInterval(this.pendingStatusInterval);
    if (!this.logs || !this.logs.length) return;
    if (this.currentSearchPayload.pollingId)
      clearInterval(this.currentSearchPayload.pollingId);
    this.logs.forEach((elem) => {
      if (elem.pollingId) {
        clearTimeout(elem.pollingId);
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}

.top {
  width: 100%;
}
.container {
  position: relative;
  height: 90vh;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin: 24px;
}
.manual-text {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #41434c;
}
.group-name-container {
  :deep(.nitrozen-tab-active[data-v-cfd6b808]) {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
  }
}
.group-tab {
  border-bottom: 1px solid #e4e5e6;
  :deep(.nitrozen-tab-item) {
    padding-bottom: 15px;
  }
  :deep(.nitrozen-tab) {
    padding: 24px 24px 0px 24px !important;
  }
}
.tab-content {
  position: relative;
  right: 20px;
  padding: 24px;
  width: 99%;
  background-color: @White;
}
.inventory-helper {
  width: 500px;
  margin-bottom: 15px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  height: 34px;
  color: #666666;
}
.inventory-helper-report {
  margin-bottom: 15px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  height: 34px;
  color: #666666;
}
.date {
  display: flex;
  margin-bottom: 26px;
  position: relative;
  bottom: 20px;
  .date-picker {
    width: 255px;
    color: #666666;
    height: 40px;
    margin-right: 20px;
    margin-top: 25px;
  }
}
.date-1 {
  display: flex;
  margin-left: 10px;
  margin-top: 13px;
  :deep(.vue-date-picker) {
    display: flex;
  }
  :deep(.mx-input) {
    font-size: 12px;
  }
  :deep(.vue-date-picker .new) {
    margin-top: 9px;
    margin-right: 12px;
    font-size: 12px;
  }
  .trace-date-picker {
    width: 255px;
    color: #666666;
    height: 40px;
    margin-right: 20px;
    margin-top: 25px;
  }

  .export-btn {
    margin-top: 23px;
    width: 125px;
    height: 40px;
    min-width: 165px;
    text-decoration: none;
    margin-left: auto;
    // margin-right: 28px;
  }
}
.refresh-icon {
  margin-left: auto;
  margin-top: 20px;
}

.headers {
  display: flex;
  // background-color: @Alabaster2;
  // border: @BorderRadius;
  background: #f6f6f6;
  /* colour/border */

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  .header {
    // flex: 0.2;
    padding: 15px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
  }
  .sync-col-width {
    flex: 0.1;
  }
}
.empty-space {
  padding: 10px;
}
.announcement {
  background-color: #f9d461;
  height: 65px;
  padding-top: 5px;
  font-weight: 500;
}
.announcement-text {
  margin-left: 40px;
  display: block;
  padding-top: 10px;
  font-size: 14px;
}

.trigger-values {
  .trigger-date-picker {
    margin-top: 12px;
    position: relative;
    bottom: 15px;
    :deep(.new) {
      color: #9b9b9b;
      position: relative;
      bottom: 5px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }
  }
  .store-sku-checkbox {
    display: flex;
    margin-top: 12px;
    ::v-deep .nitrozen-checkbox-container .nitrozen-option {
      position: relative;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.5s;
      min-height: 50px;
    }
  }
  
  .sku-store-tooltip{
    margin: 2px 0px 2px 2px;
    ::v-deep .nitrozen-tooltiptext {
      left: -50px;
      padding: 10px;
      line-height: 1.6;
      margin-bottom: 5px;
    }
    ::v-deep .nitrozen-tooltip-top:after {
      left: 60%;
    }
  }
  
  .sku-store-div{
    position: relative;
    left: 300px;
    margin-bottom: 10px;
  }
  .brand-loc-dropdown {
    width: fit-content;
    .brand-loc-dropdown-1 {
      display: flex;
      margin-top: 12px;
      position: relative;
      bottom: 15px;
      :deep(.nitrozen-dropdown-container .nitrozen-option) {
        position: relative;
        font-size: 14px;
        color: #41434c;
        line-height: 22px;
        cursor: pointer;
        transition: all 0.5s;
        min-height: 50px;
      }
    }
  }
  .section-background {
    background: white;
    border-radius: 4px;
    border: 1px solid #f3f3f3;
    padding: 0px 0px 24px 0px;
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #41434c;
      margin-left: 24px;
      margin-top: 12px;
    }
  }
}
h6 {
  margin: 24px;
}
.fp-sync {
  margin: 0px 36px 24px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e5e6;
  padding: 12px;
  border-radius: 5px;
  align-items: center;
  .title-desc-block {
    flex: 0.95 1 0%;
  }
  .title {
    font-weight: 700;
    width: 150px;
  }
  .description {
    font-size: 11px;
    color: #9b9b9b;
  }
  .resource-sync-last-job {
    font-size: 12px;
    color: #9b9b9b;
    /* color: #9b9b9b; */
    .resource-sync-last-job-date {
      font-weight: 600;
      color: black;
    }
  }
}
.sync-button {
  // margin-top: 12px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 24px 0px 0px;
}

.row {
  border-bottom: 1px solid @Iron;
  margin-bottom: 12px;
  display: flex;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-radius: @BorderRadius;
  .row-value {
    display: flex;
    align-items: center;
    flex: 0.2;
    padding: 10px 10px;
    margin-right: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }
  .sync-col-width {
    flex: 0.1;
  }
  .header-tooltip {
    position: relative;
    bottom: 5px;
  }
}

.date-tooltip {
  position: relative;
  left: 105px;
  top: 10px;
}
.identifier-suffix {
  width: 255px;
  margin-left: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  :deep(.nitrozen-dropdown-label) {
    font-size: 12px;
    font-weight: 400;
  }
}
.identifier-suffix-1 {
  width: 255px;
  margin-right: 24px;
  margin-left: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  :deep(.nitrozen-dropdown-label) {
    font-size: 12px;
    font-weight: 400;
  }
  :deep(.nitrozen-select-wrapper .nitrogen-dropdown-open .nitrogen-options) {
    min-height: 50px;
    position: relative;
    display: block;
    font-size: 14px;
    color: #41434c;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.5s;
  }
}
.identifier-suffix-2 {
  width: 170px;
}
.section {
  margin: 24px 0;
  display: flex;
  .heading-section {
    width: 12%;
    .is-active {
      background: #f5f8ff;
    }

    .heading {
      padding: 24px;
      cursor: pointer;
      .name {
        font-weight: 400;
        font-size: 14px;
      }
      .bold {
        font-weight: 600;
      }
    }
  }
  .input-section {
    padding: 24px;
    background: #f5f8ff;
    border-radius: 4px;
    width: 100%;
    :deep(.mx-input) {
      border-radius: 3px;
      height: 30px;
    }
    .error {
      margin-top: 8px;
      font-weight: 300;
    }
  }
  .button {
    display: flex;
    margin-top: 24px;
    margin-bottom: 8px;
    color: #2e31a1;
    .toggle {
      display: flex;
    }

    .choice {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .info {
    font-size: 12px;
    font-weight: 300;
    margin-left: 53px;
  }
}

.trigger-heading {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}
.manual-desc {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #666666;
}
.search-box-plus-log {
  display: flex;
  position: relative;
  top: 12px;
  margin-left: 12px;
  :deep(.nitrozen-dropdown-container .nitrozen-select__trigger) {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #9b9b9b;
  }
}
.search-identifier {
  width: 1500px;
}
.logs-btn {
  margin-left: 25px;
  min-width: 124px;
  width: 136px;
  height: 40px;
}
.top-container {
  display: flow-root;
  background: #f8f8f8;
  border-radius: 4px;
  height: 140px;
}
.dropdowns {
  display: flex;
  margin-top: 24px;
  :deep(.nitrozen-dropdown-container .nitrozen-select__trigger span) {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    color: #41434c;

    opacity: 0.8;
  }
}
.identifier-suffix-dropdown {
  width: 300px;
  margin-left: 50px;
  :deep(.nitrozen-dropdown-label) {
    margin-bottom: 5px;
  }
}
.identifier-suffix-dropdown-1 {
  width: 300px;
  :deep(.nitrozen-dropdown-label) {
    margin-bottom: 5px;
  }
}
.row .row-value[data-v-81d7a4ae] {
  display: flex;
  align-items: center;
  flex: 0.2;
  padding: 15px 10px;
  margin-right: 10px;
}
.trace-headers {
  display: flex;
  margin-top: 34px;
  padding: 0 12px;
  background: #f6f6f6;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  .header {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #41434c;
    flex: 0.2;
    padding: 15px 10px;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  .sync-col-width {
    flex: 0.1;
  }
}
.last-job-block {
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  column-gap: 8px;
  span {
    font-size: 12px;
    span {
      font-weight: 600;
    }
    .redirect-report {
      display: inline;
      a {
        color: #1216a1;
        cursor: pointer;
        font-weight: 600;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.processingInJob {
  display: flex;
}
.running-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid blue;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
